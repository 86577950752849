<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>

		<el-card class="box-card order-detail">
			<div slot="header" class="clearfix">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/member/order_list' }">订单列表</el-breadcrumb-item>
					<el-breadcrumb-item>订单详情</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div v-loading="loading">
				<template v-if="orderDetail">

					<div class="order-status">
						<h4>
							订单状态：
							<span class="ns-text-color">{{ orderDetail.order_status_name }}</span>
						</h4>
						<div v-if="orderDetail.order_status == 0" class="go-pay">
							<p>
								需付款：
								<span>￥{{ orderDetail.pay_money }}</span>
							</p>
						</div>

						<div class="operation" v-if="orderDetail.action.length > 0">
							<el-button type="primary" size="mini" plain v-if="orderDetail.is_evaluate == 1"
								@click="operation('memberOrderEvaluation')">
								<template v-if="orderDetail.evaluate_status == 0">
									评价
								</template>
								<template v-else-if="orderDetail.evaluate_status == 1">
									追评
								</template>
							</el-button>

							<el-button type="primary" size="mini"
								:plain="operationItem.action == 'orderPay' ? false : true"
								v-for="(operationItem, operationIndex) in orderDetail.action" :key="operationIndex"
								@click="operation(operationItem.action)">
								{{ operationItem.title }}
							</el-button>
						</div>
						<div class="operation"
							v-else-if="orderDetail.action.length == 0 && orderDetail.is_evaluate == 1">
							<el-button type="primary" size="mini" plain v-if="orderDetail.is_evaluate == 1"
								@click="operation('memberOrderEvaluation')">
								<template v-if="orderDetail.evaluate_status == 0">
									评价
								</template>
								<template v-else-if="orderDetail.evaluate_status == 1">
									追评
								</template>
							</el-button>
						</div>
						<div style="margin-top: 10px;">
							<el-button type="primary" size="mini" @click="exportSelect()">下载清单</el-button>
						<el-button type="primary" size="mini" v-print="printobj">打印订单</el-button>
						
						<el-button type="primary" size="mini" @click="buyagain(orderDetail.order_id)">全部加入购物车</el-button>
						</div>
					</div>
				<div  id="printSection">
				<div  class="dynysjcontent">
						<div class="order-info">
							<h4>订单信息</h4>
							<ul>
								<!-- <li>
								<i class="iconfont iconmendian"></i>
								店铺：
								<router-link :to="'/shop-' + orderDetail.site_id" target="_blank">{{ orderDetail.site_name }}</router-link>
							</li> -->
								<li>订单类型：{{ orderDetail.order_type_name }}</li>
								<li>订单编号：{{ orderDetail.order_no }}</li>
								<li>订单交易号：{{ orderDetail.out_trade_no }}</li>
								<li>配送方式：{{ orderDetail.delivery_type_name }}</li>
								<li v-if="orderDetail.order_status == -1">退款途径：{{ orderDetail.refund_money_type == 1 ?
									'原路退款' :
									orderDetail.refund_money_type == 2 ? '线下退款' : '退款到余额' }}</li>
								<li>创建时间：{{ $util.timeStampTurnTime(orderDetail.create_time) }}</li>
								<li v-if="orderDetail.close_time > 0">关闭时间：{{
									$util.timeStampTurnTime(orderDetail.close_time) }}</li>

<li >原始金额：{{ orderDetail.goods_money }}</li>
                            <li>余额支付：{{ orderDetail.balance_money }}</li>
                            <li>优惠金额：{{discountsPice(orderDetail)}}</li>
                            <li>应付金额：{{ orderDetail.pay_money }}</li>
                            <li v-if="orderDetail.employeename">开票员：{{ orderDetail.employeename }}  {{orderDetail.employee_mobile || ''}}</li>
                          <li  v-if="orderDetail.saler_info&&orderDetail.saler_info.fenxiao_name">业务员：{{ orderDetail.saler_info.fenxiao_name }}  {{orderDetail.saler_info.mobile || ''}}</li>
								<template v-if="orderDetail.pay_status > 0">
									<li>支付方式：{{ orderDetail.pay_type_name }}</li>
									<li>支付时间：{{ $util.timeStampTurnTime(orderDetail.pay_time) }}</li>
								</template>
								<li v-if="orderDetail.promotion_type_name != ''">店铺活动：{{ orderDetail.promotion_type_name
									}}</li>
								<li v-if="orderDetail.buyer_message != ''">买家留言：{{ orderDetail.buyer_message }}</li>
							</ul>
						</div>

						<div class="take-delivery-info">
							<h4>收货信息</h4>
							<ul>
								<li>收货人：{{ orderDetail.name }}</li>
								<li>手机号码：{{ orderDetail.mobile }}</li>
								<li>收货地址：{{ orderDetail.full_address }} {{ orderDetail.address }}</li>
								<li>手机号码：{{ orderDetail.mobile }}</li>
							</ul>
						</div>

						<!-- 发票信息 -->
						<div class="take-delivery-info" v-if="orderDetail.is_invoice == 1">
							<h4>发票信息</h4>
							<ul>
								<li>发票类型：{{ orderDetail.invoice_type == 1 ? '纸质发票' : '电子发票' }}</li>
								<li>发票抬头类型：{{ orderDetail.invoice_title_type == 1 ? '个人' : '企业' }}</li>
								<li>发票抬头：{{ orderDetail.invoice_title }}</li>
								<li>发票内容：{{ orderDetail.invoice_content }}</li>
								<li v-if="orderDetail.invoice_type == 1">发票邮寄地址地址：{{ orderDetail.invoice_full_address }}
								</li>
								<li v-else>发票接收邮箱：{{ orderDetail.invoice_email }}</li>
							</ul>
						</div>

	
					<nav class="nav">
						<li>排序</li>
							<li :class="{ 'no-operation': !orderDetail.is_enable_refund }">商品信息</li>
							<li>经营类型</li>
							<li>单价</li>
							<li>购买数量</li>
							<li>未出库数量</li>
							<li>小计</li>
							<li v-if="orderDetail.is_enable_refund">操作</li>
					</nav>

						<!-- 订单项·商品 -->
						<div class="list">
							<ul class="item" v-for="(goodsItem, goodsIndex) in orderDetail.order_goods"
								:key="goodsIndex">
								<li>{{goodsIndex+1}}</li>
								<li :class="[{ 'no-operation': !orderDetail.is_enable_refund },'goods_infoBox']">
									<div class="img-wrap" @click="$router.pushToTab('/sku-' + goodsItem.sku_id)">
										<img :src="$img(goodsItem.sku_image, { size: 'mid' })"
											@error="imageError(goodsIndex)" />
										<!-- <div v-for="(item,index) in goodsItem.promotion_type_name "  :key="index"  v-if="goodsItem.promotion_type_name.length>0">
										  {{item}}
										</div> -->
									</div>
									<div class="info-wrap">
										<h5 @click="$router.pushToTab('/sku-' + goodsItem.sku_id)">{{ goodsItem.sku_name
											}}</h5>
										<ctrlShowText :info="goodsItem" type="custom" size="12px" gap="10px" color="#9a9a9a"
										:wesArr="['规格','效期','厂家']" :traArr="['规格','生产日期','厂家']" :expandArr="['税率','ERPID']"></ctrlShowText>
									</div>
								</li>
								<li>
                                <span>{{goodsItem.business_scope_name}}</span>
                                </li>
								<li :style="returnShowMoreInfo(goodsItem)">
                                <template v-if="(goodsItem.promotion_type_name.includes('秒杀') || goodsItem.promotion_type_name.includes('折扣')|| goodsItem.promotion_label==='wholesale') && goodsItem.num > goodsItem.discount_goods_limit">
                                    <span>￥{{ goodsItem.original_price }} x{{ goodsItem.num - goodsItem.discount_goods_limit }}</span>
                                    <span style="margin-bottom: 78px;">￥{{ goodsItem.price }} x{{ goodsItem.discount_goods_limit }}</span>
                                </template>
                                <template v-else>
                                    <span>￥{{ goodsItem.price }}</span>
                                </template>
                                </li>
								<li>
									<span>{{ goodsItem.num }}</span>
								</li>
								<li>
									<span>{{goodsItem.num - goodsItem.issue_num}}</span>
								</li>
								<li>
									<span>￥{{ (goodsItem.price * goodsItem.num).toFixed(2) }}</span>
								</li>
								<li v-if="orderDetail.is_enable_refund&&orderDetail.pay_type!='POINT'">
									<el-button type="primary" plain size="mini" v-if="goodsItem.refund_status == 0"
										@click="$router.push({ path: '/member/refund', query: { order_goods_id: goodsItem.order_goods_id, order_id: orderId } })">
										退款
									</el-button>
									<el-button type="primary" plain size="mini" v-else
										@click="$router.push({ path: '/member/refund_detail', query: { order_goods_id: goodsItem.order_goods_id } })">
										查看退款
									</el-button>
								</li>
							</ul>
						</div>

						<!-- 订单总计 -->
						<ul class="total">
							<li>
								<label>商品金额：</label>
								<span>￥{{ orderDetail.goods_money }}</span>
							</li>
							<li>
								<label>运费：</label>
								<span>￥{{ orderDetail.delivery_money }}</span>
							</li>
							<li v-if="orderDetail.member_card_money > 0">
								<label>客户卡：</label>
								<span>￥{{ orderDetail.member_card_money }}</span>
							</li>
							<li v-if="orderDetail.invoice_money > 0">
								<label>税费：</label>
								<span>￥{{ orderDetail.invoice_money }}</span>
							</li>
							<li v-if="orderDetail.invoice_delivery_money > 0">
								<label>发票邮寄费：</label>
								<span>￥{{ orderDetail.invoice_delivery_money }}</span>
							</li>
							<li v-if="orderDetail.adjust_money != 0">
								<label>订单调整：</label>
								<span>
									<template v-if="orderDetail.adjust_money < 0">
										-
									</template>
									<template v-else>
										+
									</template>
									￥{{ orderDetail.adjust_money | abs }}
								</span>
							</li>
							<!-- <li v-if="orderDetail.promotion_money > 0">
								<label>优惠：</label>
								<span>￥{{ orderDetail.promotion_money }}</span>
							</li> -->
							<!-- <li v-if="orderDetail.coupon_money > 0">
								<label>优惠券：</label>
								<span>￥{{ orderDetail.coupon_money }}</span>
							</li> -->
							<li v-if="orderDetail.point_money > 0">
								<label>积分抵扣：</label>
								<span>￥{{ orderDetail.point_money }}</span>
							</li>
							<li v-if="orderDetail.balance_money > 0">
								<label>使用余额：</label>
								<span>￥{{ orderDetail.balance_money }}</span>
							</li>
							 <li v-if="orderDetail.coupon_money">
								<label>优惠劵：</label>
								<span>
								￥{{orderDetail.coupon_money}}</span> </li>
							 <li  v-if="orderDetail.coupon_money">
								<label>满减优惠：</label>
								 <span >
								￥{{orderDetail.promotion_money}}</span> </li> 
							<!-- <li> 
								<label>邮费：</label>
								<span  v-if="orderDetail.coupon_money">￥{{orderDetail.delivery_money}}</span></li> -->
							<li class="pay-money">
								<label>实付款：</label>
								<span>￥{{ orderDetail.pay_money }}</span>
							</li>
						</ul>
				 </div>
					</div>
				</template>
			</div>
		</el-card>
	</div>
</template>

<script>
import * as XLSX from "xlsx";
import {
	apiOrderDetail,
	apibuyagain
} from '@/api/order/order';
import orderMethod from '@/utils/orderMethod';
import {
	mapGetters
} from 'vuex';
import ctrlShowText from "@/components/ctrl-show-text/ctrl-show-text.vue";
export default {
	name: 'order_detail',
	components: {ctrlShowText},
	mixins: [orderMethod],
	data: () => {
		return {
			orderId: 0,
			orderDetail: null,
			loading: true,
			yes: true,
			printobj: {
				id: "#printSection",
				popTitle: '订单信息',
			}
		};
	},
	created() {
		this.orderId = this.$route.query.order_id;
		this.getOrderDetail();
	},
	mounted() {
		let self = this;
		setTimeout(function () {
			self.yes = false
		}, 300)
	},
	computed: {
		...mapGetters(['defaultGoodsImage'])
	},
	methods: {
		returnShowMoreInfo(goodsItem){
            let bool = ((goodsItem.promotion_type_name.includes('秒杀') || goodsItem.promotion_type_name.includes('折扣')|| goodsItem.promotion_label==='wholesale') && goodsItem.num > goodsItem.discount_goods_limit);
            if(bool){
                return 'display: flex;flex-direction: column;line-height: 20px;padding-top: 30px;box-sizing: border-box;'
            }
            return '';
        },
		discountsPice(orderDetail){
			console.log(typeof(orderDetail.pay_money));
			
			console.log((Number(orderDetail.goods_money) + Number( orderDetail.delivery_money) ) - Number(orderDetail.pay_money));
			
		let  discounts= (Number(orderDetail.goods_money) + Number( orderDetail.delivery_money) ) - Number(orderDetail.pay_money)
			return  discounts.toFixed(2)
		},
		buyagain(id) {
            apibuyagain({
				order_id:id
			})
                .then(res => { 
				 if(res.code>=0){
					this.$router.push('/cart')
				 }
				})
                .catch(err => {
					console.log(err)
				})
        },
		exportSelect() {
			//  导出选中
			this.$confirm("是否确认导出当前订单商品列表?", "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			}).then((response) => {
				//  this.selectdata 是复选框的 数据列表
				if (this.orderDetail.order_goods <= 0) {
					this.$message.info('暂无商品信息！');
					return false;
				}
				let tableData = [
					['序号', '商品名称', '产地', '规格', '效期', "ERPID", "单价", "数量", "小计"]//导出表头
				] // 表格表头
				this.orderDetail.order_goods.forEach((item, index) => {
					let rowData = []
					let num = (item.price * item.num).toFixed(2)
					//导出内容的字段
					rowData = [
						index + 1,
						item.goods_name,
						item.goods_attr_format.attr_factory,
						item.goods_attr_format.attr_specs,
						item.goods_attr_format.attr_validity,
						item.goods_erp_spid,
						item.price,
						item.num,
						num
					]
					tableData.push(rowData)
				})
				let workSheet = XLSX.utils.aoa_to_sheet(tableData);
				let bookNew = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(bookNew, workSheet, '') // 工作簿名称
				let name = '商品清单' + this.timeFormat() + '.xlsx'
				XLSX.writeFile(bookNew, name) // 保存的文件名
			})
		},
		timeFormat() {
			let time = new Date();
			let year = time.getFullYear();
			let month = time.getMonth() + 1;
			let date = time.getDate();
			let hours = time.getHours();
			let minutes = time.getMinutes();
			let seconds = time.getSeconds();
			return year + '-' + this.addZero(month) + '-' + this.addZero(date) + ' ' + this.addZero(hours) + ':' + this.addZero(minutes) + ':' + this.addZero(seconds);
		},
		addZero(num) {
			return num < 10 ? '0' + num : num
		},
		getOrderDetail() {
			apiOrderDetail({
				order_id: this.orderId
			})
				.then(res => {
					if (res.code >= 0) {
						this.orderDetail = res.data;
						this.loading = false;
					} else {
						this.$message({
							message: '未获取到订单信息',
							type: 'warning',
							duration: 2000,
							onClose: () => {
								this.$router.push({
									path: '/member/order_list'
								});
							}
						});
					}
				})
				.catch(err => {
					this.loading = false;
					this.$message.error({
						message: err.message,
						duration: 2000,
						onClose: () => {
							this.$router.push({
								path: '/member/order_list'
							});
						}
					});
				});
		},
		operation(action) {
			switch (action) {
				case 'orderPay': // 支付
					this.orderPay(this.orderDetail);
					break;
				case 'orderClose': //关闭
					this.orderClose(this.orderDetail.order_id, () => {
						this.getOrderDetail();
					});
					break;
				case 'memberTakeDelivery': //收货
					this.orderDelivery(this.orderDetail.order_id, () => {
						this.getOrderDetail();
					});
					break;
				case 'trace': //查看物流
					this.$router.push({
						path: '/member/logistics',
						query: {
							order_id: this.orderDetail.order_id
						}
					});
					break;
				case 'memberOrderEvaluation': //评价
					this.$router.pushToTab({
						path: '/evaluate',
						query: {
							order_id: this.orderDetail.order_id
						}
					});
					break;
			}
		},
		imageError(index) {
			this.orderDetail.order_goods[index].sku_image = this.defaultGoodsImage;
		}
	},
	filters: {
		abs(value) {
			return Math.abs(parseFloat(value)).toFixed(2);
		}
	}
};
</script>
<style>	
</style>
<style lang="scss" >
@media print { 
  .dynysjcontent {
		 nav{
		overflow: hidden;
		padding: 10px 0;
		background: #fff;
		border-bottom: 1px solid #eeeeee;

		li {
			float: left;

			&:nth-child(1) {
				width: 5%;
			}

			&:nth-child(2) {
				width: 30%;
				&.no-operation {
					width: 30%;
				}
			}

			&:nth-child(3) {
				width: 10%;
			}

			&:nth-child(4) {
				width: 15%;
			}

			&:nth-child(5) {
				width: 10%;
			}
		}
		 }
  }
  .list {
		border-bottom: 1px solid #eeeeee;

		.item {
			background-color: #fff;
			padding: 10px 0;
			overflow: hidden;

			li {
				float: left;
				line-height: 100px;

				&:nth-child(1) {
					width: 5%;
				}

				&:nth-child(2) {
					width: 30%;
					line-height: inherit;

&.no-operation {
	width: 30%;
	display: flex;
	align-items: center;
}

.img-wrap {
	cursor: pointer;

	img {
		width: 60px;
		height: 60px;
	}
}

.info-wrap {
	margin-left: 10px;

	h5 {
		font-weight: normal;
		font-size: $ns-font-size-base;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
		margin-right: 10px;
		display: inline-block;
		cursor: pointer;

		&:hover {
			color: $base-color;
		}
	}

	span {
		font-size: $ns-font-size-sm;
		color: #9a9a9a;
	}
}
				}

				&:nth-child(3) {
					width: 10%;
				}

				&:nth-child(4) {
					width: 15%;
				}

				&:nth-child(5) {
					width: 10%;
				}
			}
		}
		.order-status {
		background-color: #fff;
		margin-bottom: 20px;

		h4 {
			margin: 10px 0 20px;
			border-bottom: 1px solid #eeeeee;
			padding-bottom: 10px;
		}

		.go-pay {
			p {
				display: inline-block;
				vertical-align: middle;

				span {
					font-weight: bold;
					color: $base-color;
					font-size: 18px;
				}
			}
		}

		.operation {
			margin-top: 10px;
		}
	}
	}
	.total {
		padding: 20px;
		background-color: #fff;
		text-align: right;

		li {
			span {
				width: 150px;
				display: inline-block;
			}

			&.pay-money {
				font-weight: bold;

				span {
					color: $base-color;
					font-size: 16px;
					vertical-align: middle;
				}
			}
		}
	}
	.order-info {
		background-color: #fff;
		margin-bottom: 10px;

		h4 {
			margin: 10px 0 20px;
			border-bottom: 1px solid #eeeeee;
			padding-bottom: 10px;
		}

		ul {
			display: flex;
			flex-wrap: wrap;
			li {
				flex: 0 0 35.5%;
				margin-bottom: 10px;

				&:last-child {
					flex: initial;
				}
				&:nth-child(3) {
				  width: 10%;
			}

			}
			
		}
	}

}
.box {
	width: 100%;
	position: relative;
}

.null-page {
	width: 100%;
	height: 730px;
	background-color: #FFFFFF;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
}

.order-detail {
	.order-status {
		background-color: #fff;
		margin-bottom: 20px;

		h4 {
			margin: 10px 0 20px;
			border-bottom: 1px solid #eeeeee;
			padding-bottom: 10px;
		}

		.go-pay {
			p {
				display: inline-block;
				vertical-align: middle;

				span {
					font-weight: bold;
					color: $base-color;
					font-size: 18px;
				}
			}
		}

		.operation {
			margin-top: 10px;
		}
	}

	.order-info {
		background-color: #fff;
		margin-bottom: 10px;

		h4 {
			margin: 10px 0 20px;
			border-bottom: 1px solid #eeeeee;
			padding-bottom: 10px;
		}

		ul {
			display: flex;
			flex-wrap: wrap;

			li {
				flex: 0 0 33.3333%;
				margin-bottom: 10px;

				&:last-child {
					flex: initial;
				}
			}
		}
	}

	.take-delivery-info {
		background-color: #fff;
		margin-bottom: 10px;

		h4 {
			margin: 10px 0 20px;
			border-bottom: 1px solid #eeeeee;
			padding-bottom: 10px;
		}

		ul {
			display: flex;
			flex-wrap: wrap;

			li {
				flex: 0 0 33.3333%;
				margin-bottom: 10px;

				&:last-child {
					flex: initial;
				}
			}
		}
	}

	nav {
		overflow: hidden;
		padding: 10px 0;
		background: #fff;
		border-bottom: 1px solid #eeeeee;

		li {
			float: left;

			&:nth-child(1) {
				width: 5%;
			}

			&:nth-child(2) {
				width: 30%;
				&.no-operation {
					width: 30%;
				}
			}

			&:nth-child(3) {
				width: 10%;
			}

			&:nth-child(4) {
				width: 10%;
			}

			&:nth-child(5) {
				width: 10%;
			}
			&:nth-child(6) {
				width: 15%;
			}
			&:nth-child(7) {
				width: 10%;
			}
		}
	}

	.list {
		border-bottom: 1px solid #eeeeee;

		.item {
			background-color: #fff;
			padding: 10px 0;
			overflow: hidden;

			li {
				float: left;
				line-height: 100px;

				&:nth-child(1) {
					width: 5%;
	
				}

				&:nth-child(2) {
					width: 30%;
					line-height: inherit;

&.no-operation {
	width: 30%;
	display: flex;
	align-items: center;
}

.img-wrap {
	cursor: pointer;

	img {
		width: 60px;
		height: 60px;
	}
}

.info-wrap {
	margin-left: 10px;

	h5 {
		font-weight: normal;
		font-size: $ns-font-size-base;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
		margin-right: 10px;
		display: inline-block;
		cursor: pointer;

		&:hover {
			color: $base-color;
		}
	}

	span {
		font-size: $ns-font-size-sm;
		color: #9a9a9a;
	}
}
				}

				&:nth-child(3) {
					width: 10%;
					span{
						display: inline-block;
					}
				}

				&:nth-child(4) {
					width: 10%;
				}

				&:nth-child(5) {
					width: 10%;
				}
				&:nth-child(6) {
					width: 15%;
				}
				&:nth-child(7) {
					width: 10%;
				}
			}
		}
	}

	// 总计
	.total {
		padding: 20px;
		background-color: #fff;
		text-align: right;

		li {
			span {
				width: 150px;
				display: inline-block;
			}

			&.pay-money {
				font-weight: bold;

				span {
					color: $base-color;
					font-size: 16px;
					vertical-align: middle;
				}
			}
		}
	}
}
.goods_infoBox{
   display: flex;
   >div:nth-child(1){
	flex-shrink: 0;
   }
   >div:nth-child(2){
	width: calc(100% - 80px);
   }
}
</style>
